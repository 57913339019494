import homepage from './homepage.js';
import contactpage from './contactpage.js';
import navbar from './navbar.js';

const index = {
    homepage,
    contactpage,
    navbar
}

export default index;