const contactpage = {
    contact: "Contacto",
    firstName: "Nombre",
    lastName: "Apellido",
    email: "Correo electrónico",
    subject: "Tema",
    message: "Mensaje",
    send: "Enviar"
}

export default contactpage