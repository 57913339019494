import homepage from './homepage.js';
import contactpage from './contactpage.js';
import navbar from './navbar.js';

const es = {
    homepage,
    contactpage,
    navbar
}

export default es;