const contactpage = {
    contact: "Contact",
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email",
    subject: "Subjet",
    message: "Message",
    send: "Send"
}

export default contactpage;