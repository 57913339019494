const contactpage = {
    contact: "Contact",
    firstName: "Prenom",
    lastName: "Nom",
    email: "Courriel",
    subject: "Sujet",
    message: "Message",
    send: "Envoyer"
}

export default contactpage;